import { PassengerTypeEnum } from 'api-hooks/booking/model';
import * as Yup from 'yup';

export const shippingTabFormSchema = () =>
  Yup.object().shape({
    sourceLabor: Yup.string().nullable().required(),
    destinationLabor: Yup.string().nullable().required(),
    depatureDate: Yup.date().nullable().required(),
    crossingType: Yup.number().nullable().required(),
    vehicleType: Yup.object({
      value: Yup.string().strip(true),
      label: Yup.string().strip(true),
      caption: Yup.string().strip(true),
      max: Yup.number().strip(true),
      qty: Yup.number().min(1).max(1).required(),
    })
      .nullable()
      .when('crossingType', {
        is: (crossingType) => crossingType > 0,
        then: Yup.object()
          .shape({
            value: Yup.string().strip(true),
            label: Yup.string().strip(true),
            caption: Yup.string().strip(true),
            qty: Yup.number().min(1).max(1).required(),
          })
          .nullable()
          .required(),
      }),
    passengerCount: Yup.array(
      Yup.object({
        value: Yup.string().strip(true),
        label: Yup.string().strip(true),
        caption: Yup.string().strip(true),
        qty: Yup.number()
          .min(0)
          .max(10)
          .required()
          .when('value', {
            is: (value) => value === PassengerTypeEnum.Adult,
            then: Yup.number().min(1).max(10).required(),
            otherwise: Yup.number().min(0).max(10).required(),
          }),
      }),
    )
      .nullable()
      .when('crossingType', {
        is: (crossingType) => crossingType % 2 === 0,
        then: Yup.array(
          Yup.object({
            value: Yup.string().strip(true),
            label: Yup.string().strip(true),
            caption: Yup.string().strip(true),
            qty: Yup.number()
              .min(0)
              .max(10)
              .required()
              .when('value', {
                is: (value) => value === PassengerTypeEnum.Adult,
                then: Yup.number().min(1).max(10).required(),
                otherwise: Yup.number().min(0).max(10).required(),
              }),
          }),
        )
          .nullable()
          .length(2, '')
          .required(),
      }),
    maxPassenger: Yup.number().default(0),
  });
