import { yupResolver } from '@hookform/resolvers/yup';
import { PassengerTypeEnum } from 'api-hooks/booking/model';
import {
  useGetPassengerTypes,
  useGetVehicleTypes,
} from 'api-hooks/voyage/query';
import { utcToLocalDate } from 'common/utils/date';
import Stack from 'components/commons/stack';
import Text from 'components/commons/text';
import Button from 'components/elements/button';
import Input from 'components/elements/field';
import Form from 'components/elements/form';
import { StepperSelectOption } from 'components/elements/stepper-select';
import { format } from 'date-fns';
import ArrivalVoyageSelectInput from 'modules/select-inputs/arrival-voyage';
import CrossingTypeSelectInput from 'modules/select-inputs/crossing-type';
import DepartureVoyageSelectInput from 'modules/select-inputs/departure-voyage';
import PassengerTypesSelectInput from 'modules/select-inputs/passenger-facility';
import VehicleTypesSelectInput from 'modules/select-inputs/vehicle-facility';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import qs from 'qs';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { CSS } from 'styles';

import { shippingTabFormSchema } from './form-type';
import { styles } from '../style';

type FormType = {
  sourceLabor: string | null;
  destinationLabor: string | null;
  depatureDate: Date | null;
  crossingType: number | null;
  passengerCount:
    | {
        label: string;
        caption?: string;
        qty: number;
        value: string;
      }[]
    | null;
  vehicleType: {
    value: string;
    label: string;
    caption: string;
    qty: number;
    max: number;
  } | null;
  facility?: boolean;
};

type Props = {
  title?: string;
  titleColor?: string;
  message?: string;
  messageColor?: string;
  cancelable?: boolean;
  onCancel?: () => void;
  onSubmit?: (
    values: FormType,
    queryParams: string,
  ) => Promise<any | undefined>;
  cancelText?: string;
  headerCss?: CSS;
  changeRoute: boolean;
};

const convertSelectStepperArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      ...(item.qty > 0 && { [item[key]]: item.qty }),
    };
  }, initialValue);
};

export default function ShippingTab(props: Props) {
  const { t } = useTranslation();
  const { query } = useRouter();
  const [isInitiateValues, setIsInitiateValues] = React.useState(false);

  const {
    cancelable,
    message = t('common:where_do_you_want_to_sail'),
    messageColor = '$primary',
    onCancel,
    cancelText = t('common:cancel'),
    onSubmit,
    title = t('common:welcome_to_wjl'),
    titleColor = '$primary',
    headerCss,
    changeRoute = false,
  } = props;

  const crossingTypeCheck = React.useCallback((crossingType: number | null) => {
    switch (crossingType) {
      case 0:
        return {
          isRenderPassenger: true,
          isRenderVehicle: false,
          isRenderBoth: false,
        };
      case 1:
        return {
          isRenderPassenger: false,
          isRenderVehicle: true,
          isRenderBoth: false,
        };
      case 2:
        return {
          isRenderPassenger: true,
          isRenderVehicle: true,
          isRenderBoth: true,
        };
      default:
        return {
          isRenderPassenger: false,
          isRenderVehicle: false,
          isRenderBoth: false,
        };
    }
  }, []);

  const {
    data: dataPassengers,
    isFetching: fetchingPassenger,
    isLoading: loadingPassenger,
  } = useGetPassengerTypes();

  const optionPassengers = React.useMemo<StepperSelectOption[]>(() => {
    if (dataPassengers?.data) {
      const _options = dataPassengers.data.map((item) => {
        const { fromAge, toAge } = item;

        return {
          value: item.name,
          label: t(`common:${item.name}`),
          caption:
            item.fromAge && item.toAge
              ? t('common:between_age_caption', { fromAge, toAge })
              : item.fromAge
              ? t('common:greater_age_caption', { fromAge })
              : item.toAge
              ? t('common:below_age_caption', { toAge })
              : '',
          qty: query[item.name] ? parseFloat(`${query[item.name]}`) : 0,
          max: 10,
        };
      });

      return _options;
    }
    return [];
  }, [dataPassengers?.data, t, query]);

  const {
    data: dataVehicle,
    isLoading: loadingVehicle,
    isFetching: fetchingVehicle,
  } = useGetVehicleTypes();

  const optionsVehicle = React.useMemo<StepperSelectOption[]>(() => {
    if (dataVehicle?.data) {
      return dataVehicle.data.map((item) => ({
        value: item.code,
        label: item.name,
        caption: item.description,
        qty: 1,
        max: item.maxPassenger,
      }));
    }
    return [];
  }, [dataVehicle?.data]);

  const initialValues = React.useMemo(() => {
    const adult = query.adult ? Number(query.adult) : 0;
    const infant = query.infant ? Number(query.infant) : 0;
    return {
      sourceLabor: query.d ? query.d.toString() : null,
      depatureDate: query.date
        ? utcToLocalDate(query.date.toString())
        : new Date(),
      destinationLabor: query.a ? query.a.toString() : null,
      crossingType: query.type ? Number(query.type) : null,
      passengerCount: [
        {
          value: PassengerTypeEnum.Adult,
          label: t('common:adult'),
          qty: adult,
        },
        {
          value: PassengerTypeEnum.Infant,
          label: t('common:infant'),
          qty: infant,
        },
      ],
      vehicleType: null,
    };
  }, [query, t]);

  const resolver = yupResolver(shippingTabFormSchema()) as any;
  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver,
    mode: 'all',
  });

  const { watch, setError, getValues, setValue, clearErrors } = methods;

  const formData = {
    vehicleType: watch('vehicleType'),
    crossingType: watch('crossingType'),
    sourceLabor: watch('sourceLabor'),
    destinationLabor: watch('destinationLabor'),
  };

  React.useEffect(() => {
    if (!isInitiateValues && !!dataPassengers?.data && !!dataVehicle?.data) {
      const foundVehicle =
        optionsVehicle.find((vehicle) => {
          return Object.keys(query).find((key) => {
            return key === vehicle.value;
          });
        }) || null;

      methods.reset({
        ...initialValues,
        passengerCount: [...optionPassengers],
        vehicleType: foundVehicle,
      });

      setIsInitiateValues(true);
    }
  }, [
    dataPassengers,
    dataVehicle,
    initialValues,
    isInitiateValues,
    methods,
    optionPassengers,
    optionsVehicle,
    query,
  ]);

  const _onSubmit = React.useCallback(async () => {
    const _values = getValues();

    try {
      let hasError = false;

      const passenger = convertSelectStepperArrayToObject(
        _values.passengerCount,
        'value',
      );

      const vehicleType = _values.vehicleType;
      const crossingType = _values.crossingType;

      const { isRenderPassenger, isRenderVehicle } =
        crossingTypeCheck(crossingType);

      const vehicle = {};

      if (vehicleType) {
        vehicle[vehicleType.value.replaceAll(' ', '-')] = 1;
      }

      if (isRenderPassenger && !passenger.adult) {
        setError('passengerCount', {
          message: t('common:adult_required'),
        });
        hasError = true;
      } else {
        if (passenger.adult < passenger.infant) {
          setError('passengerCount', {
            message: t(
              'common:adult_passenger_must_more_than_infant_passenger',
            ),
          });
          hasError = true;
        }
      }

      if (isRenderVehicle && Object.keys(vehicle).length === 0) {
        setError('vehicleType', {
          message: t('common:vehicle_required'),
        });
        hasError = true;
      }

      if (!hasError) {
        const values = {
          d: _values.sourceLabor,
          a: _values.destinationLabor,
          date: _values.depatureDate
            ? format(_values.depatureDate, 'yyyy-MM-dd')
            : '',
          type: _values.crossingType,
          ...(isRenderPassenger && passenger),
          ...(isRenderVehicle && vehicle),
        };
        if (onSubmit) {
          await onSubmit(_values, qs.stringify(values));
        }
      }
    } catch (error) {
    } finally {
    }
  }, [crossingTypeCheck, getValues, onSubmit, setError, t]);

  const crossingTypeHandler = React.useCallback(() => {
    setValue('passengerCount', []);
    setValue('vehicleType', null);
    clearErrors();
  }, [clearErrors, setValue]);

  const passengerTypesSelectInput = React.useCallback(() => {
    const isOrderVehicleAndPassenger = formData.crossingType === 2;

    const disabled = isOrderVehicleAndPassenger && !formData.vehicleType;

    const adultMaxPassenger = formData.vehicleType?.max ?? 10;

    const _options = optionPassengers.map((optionPassenger) => {
      return {
        ...optionPassenger,
        max: adultMaxPassenger,
      };
    });

    const hint = () => {
      if (isOrderVehicleAndPassenger) {
        if (formData.vehicleType) {
          return t('common:max_adult_extra', {
            extra: adultMaxPassenger,
          });
        } else {
          return '';
        }
      }
      return undefined;
    };

    return (
      <Stack.Item className={styles.topFieldInput()}>
        <PassengerTypesSelectInput
          hint={hint()}
          name="passengerCount"
          required
          options={_options}
          isFetching={fetchingPassenger}
          isLoading={loadingPassenger}
          disabled={disabled}
        />
      </Stack.Item>
    );
  }, [
    fetchingPassenger,
    formData.crossingType,
    formData.vehicleType,
    loadingPassenger,
    optionPassengers,
    t,
  ]);

  const vehicleSelectInput = React.useCallback(() => {
    return (
      <Stack.Item className={styles.topFieldInput()}>
        <VehicleTypesSelectInput
          name="vehicleType"
          required
          options={optionsVehicle}
          isFetching={fetchingVehicle}
          isLoading={loadingVehicle}
          onAfterChange={(params) => {
            const passengerCount =
              getValues('passengerCount')?.map((passenger) => {
                const max = params.max ?? 0;
                return {
                  ...passenger,
                  qty: passenger.qty > max ? max : passenger.qty,
                };
              }, []) ?? null;
            setValue('passengerCount', passengerCount);
          }}
        />
      </Stack.Item>
    );
  }, [fetchingVehicle, getValues, loadingVehicle, optionsVehicle, setValue]);

  const crossingTypeSelectInput = React.useCallback(() => {
    return (
      <Stack.Item className={styles.topFieldInput()}>
        <CrossingTypeSelectInput
          name="crossingType"
          required
          onChange={crossingTypeHandler}
        />
      </Stack.Item>
    );
  }, [crossingTypeHandler]);

  const { isRenderPassenger, isRenderVehicle, isRenderBoth } =
    React.useMemo(() => {
      return crossingTypeCheck(formData.crossingType);
    }, [crossingTypeCheck, formData.crossingType]);

  return (
    <Form methods={methods} onSubmit={_onSubmit}>
      <Stack gap="md">
        <Stack rootCss={headerCss}>
          <Text variant="headline4" css={{ color: titleColor }}>
            {!changeRoute ? title : t('common:change_route')}
          </Text>
          <Text variant="body3" css={{ color: messageColor }}>
            {!changeRoute ? message : t('common:where_do_you_want_to_sail')}
          </Text>
        </Stack>
        <Stack direction="horizontal" justify="between" grow gap="xs" wrap>
          <Stack.Item className={styles.topFieldInput()}>
            <DepartureVoyageSelectInput
              name="sourceLabor"
              required
              arrivalCode={formData.destinationLabor || ''}
            />
          </Stack.Item>
          <Stack.Item className={styles.topFieldInput()}>
            <ArrivalVoyageSelectInput
              name="destinationLabor"
              departureCode={formData.sourceLabor || ''}
              required
            />
          </Stack.Item>
          <Stack.Item className={styles.topFieldInput()}>
            <Input
              type="date"
              name="depatureDate"
              label={t('common:departure_date')}
              placeholder={t('common:select_date')}
              minDate={new Date()}
              required
            />
          </Stack.Item>
        </Stack>
        <Stack
          direction="horizontal"
          justify={isRenderBoth ? 'between' : 'start'}
          grow
          gap={!isRenderBoth ? 'md' : 'xs'}
          wrap
        >
          {crossingTypeSelectInput()}

          {isRenderVehicle
            ? vehicleSelectInput()
            : isRenderPassenger && passengerTypesSelectInput()}

          {isRenderBoth && passengerTypesSelectInput()}
        </Stack>
        <Stack.Item css={{ '@sm': { alignItems: 'end' } }}>
          <Stack direction="horizontal" gap="md" justify="between" grow>
            {cancelable && (
              <Button
                variant="secondary"
                onClick={onCancel}
                css={{ minWidth: 100 }}
              >
                {cancelText}
              </Button>
            )}
            <Input
              type="submit"
              text={
                !changeRoute ? t('common:search_schedule') : t('common:edit')
              }
              css={{ minWidth: 100 }}
            />
          </Stack>
        </Stack.Item>
      </Stack>
    </Form>
  );
}
