import * as React from 'react';
import { styled, keyframes, CSS, VariantProps } from 'styles';

const DEFAULT_TAG = 'div';

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {}

const pulse = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: '100%' },
});

const StyledSkeleton = styled(DEFAULT_TAG, {
  backgroundColor: '$neutral4',
  position: 'relative',
  overflow: 'hidden',

  '&::after': {
    animationName: `${pulse}`,
    animationDuration: '500ms',
    animationDirection: 'alternate',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    backgroundColor: '$neutral2',
    bottom: 0,
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  variants: {
    variant: {
      round: {
        borderRadius: 99,
        height: 50,
        width: 50,
      },
      text: {
        borderRadius: 99,
        height: 12,
        width: '100%',
      },
    },
  },
  defaultVariants: {
    variant: 'text',
  },
});

type SkeletonCSSProp = { css?: CSS };
type SkeletonVariants = VariantProps<typeof StyledSkeleton>;
export type ISkeletonProps = SkeletonCSSProp & SkeletonVariants & SkeletonProps;

export const Skeleton = React.forwardRef<HTMLDivElement, ISkeletonProps>(
  (props, ref) => <StyledSkeleton {...props} ref={ref} />,
);

Skeleton.displayName = 'Skeleton';
Skeleton.toString = () => `.${StyledSkeleton.className}`;
